<template>
	<div>
	
		<template>
			<div id="breadcrumb-alignment" class="mb-1">
				<div class="d-flex justify-content-start breadcrumb-wrapper">
					<b-breadcrumb :items="breadCrumb()" />
				</div>
			</div>
		</template>

		<b-row class="match-height">
			<b-col lg="12" md="12">
		
				<b-card title="Add Defect">

					<b-alert v-model="showDismissibleAlert" variant="danger" dismissible class="mb-1">
						<div class="alert-body">
							{{error_message}}
						</div>
					</b-alert>
				
					<b-form @submit="formSubmit">						
						<b-row>
							<b-col md="12">
								<b-row>

									<b-col md="6" >
										<b-form-group label="Project Site" class="required">
											<b-form-select v-model="form.site" @change="resetData(); getNoPhotoReporting();">
												<b-form-select-option value="" disabled>Select</b-form-select-option>
												<b-form-select-option :value="site._id" v-for="site in sites" :key="site._id">{{site.site_name}}</b-form-select-option>
											</b-form-select>
										</b-form-group>
									</b-col>

									<b-col md="6" >
										<b-form-group label="Location" class="required">
											<v-select
												v-model="form.locations"
												:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
												multiple
												label="name"
												:options="locations"
												placeholder="Select"
												class="multiselect_muliple_options"
											/>
											<p class="text-warning" v-if="form.site != ''" @click="openModel()">
												<feather-icon icon="PlusCircleIcon" />
												<span class="align-middle ml-50" style="cursor: pointer">Add Location</span>
											</p>
										</b-form-group>
									</b-col>

								</b-row>
								
								<b-row>
									<b-col md="12">
										<b-form-group label="Defect Detail" class="required">
											<b-form-input placeholder="2 to 100 characters" v-model="form.title"/>
										</b-form-group>
									</b-col>
								</b-row>


								<b-row v-if="show_additional_info == 'yes'">

									<b-col md="3" >
                
						                <b-form-group
						                  label="Signage"
						                  class=""

						                >
						                  <b-form-select v-model="form.signage">

						                    <b-form-select-option value="" disabled>Select
						                    </b-form-select-option>
						                    <b-form-select-option value="yes">{{'Yes'}}
						                    </b-form-select-option>
						                    <b-form-select-option value="no">{{'No'}}
						                    </b-form-select-option>
						                  </b-form-select>
						                    
						                </b-form-group>
						             </b-col>

						             <b-col md="3" >
                
						                <b-form-group
						                  label="Call FMCS"
						                  class=""

						                >
						                  <b-form-select v-model="form.call_fmcs">

						                    <b-form-select-option value="" disabled>Select
						                    </b-form-select-option>
						                    <b-form-select-option value="yes">{{'Yes'}}
						                    </b-form-select-option>
						                    <b-form-select-option value="no">{{'No'}}
						                    </b-form-select-option>
						                  </b-form-select>
						                    
						                </b-form-group>
						             </b-col>

						             <b-col md="3" >
                
						                <b-form-group
						                  label="Call FMCS Time"
						                  class=""

						                >
						                  <flat-pickr
					                        v-model="form.call_fmcs_time"
					                        placeholder="Select Time"
					                        class="form-control"
					                        :config="{ enableTime: true, noCalendar: true, dateFormat: 'H:i'}"
					                      />
						                    
						                </b-form-group>
						             </b-col>

						             <b-col md="3" >
                
						                <b-form-group
						                  label="Other"
						                  class=""

						                >
						                  <b-form-input
						                    placeholder=""
						                    v-model="form.other"
						                    autocomplete="off"
						                  />
						                    
						                </b-form-group>
						             </b-col>

								</b-row>

								<b-row class="mt-2 margin_top_zero_mobile">
								
									<b-col md="3" >
										<b-form-group label="Start Date" class="required">
											<b-form-datepicker v-model="form.dateRange.startDate" :date-format-options="{ day: 'numeric', month: 'short',year: 'numeric' }" locale="en"/>
										</b-form-group>
									</b-col>

									<b-col md="3" >
										<b-form-group label="End Date" class="required">
											<b-form-datepicker v-model="form.dateRange.endDate" :date-format-options="{ day: 'numeric', month: 'short',year: 'numeric' }" locale="en"/>
										</b-form-group>
									</b-col>

								</b-row>

								<b-row class="mt-1 mb-1 margin_top_zero_mobile">
									<b-col md="6">
										<b-form-group label="" class="margin_bottom_zero_mobile">
											<b-form-checkbox
												v-model="form.planned_time_modify"
												value="yes"
												unchecked-value="no"
												class="custom-control-warning"
												@change="form.plannedStartTime = '00:00'"
											>
												Planned Start Time
											</b-form-checkbox>
										</b-form-group>
									</b-col>
								</b-row>

								<b-row  v-if="form.planned_time_modify == 'yes'">
									<b-col md="6">
										<b-form-group label="Planned Start Time" class="margin_bottom_zero_mobile">
											<flat-pickr v-model="form.plannedStartTime" class="form-control" :config="{ enableTime: true, noCalendar: true, dateFormat: 'H:i'}"/>
										</b-form-group>
									</b-col>
								</b-row>
								
								<b-row class="mt-1 mb-1 margin_bottom_zero_mobile">

									<b-col md="6">
										<b-form-group label="">
											<b-form-checkbox
												v-model="form.start_modify"
												value="yes"
												unchecked-value="no"
												class="custom-control-warning"
												@change="form.startTime = '00:00'"
											>
												Start Time
											</b-form-checkbox>
										</b-form-group>
									</b-col>

									<b-col md="6">
										<b-form-group label="">
											<b-form-checkbox
												v-model="form.end_modify"
												value="yes"
												unchecked-value="no"
												class="custom-control-warning"
												@change="form.endTime = '00:00'"
											>
												End Time
											</b-form-checkbox>
										</b-form-group>
									</b-col>
							
								</b-row>

								<b-row>

									<b-col md="6" v-if="form.start_modify == 'yes'">
										<b-form-group label="Start Time" class="">
											<flat-pickr v-model="form.startTime" class="form-control" :config="{ enableTime: true, noCalendar: true, dateFormat: 'H:i'}"/>											
										</b-form-group>
									</b-col>

									<b-col md="6" v-if="form.end_modify == 'yes'">
										<b-form-group label="End Time" class="">
											<flat-pickr v-model="form.endTime" class="form-control" :config="{ enableTime: true, noCalendar: true, dateFormat: 'H:i'}"/>
										</b-form-group>
									</b-col>

								</b-row>

								<hr v-if="form.site && no_photo_reporting == 'no'">
								<b-row v-if="form.site && no_photo_reporting == 'no'">										
									<b-col cols="12" class="mb-0 mt-2">
										<h4 class="mb-0"> Images </h4>
									</b-col>
								</b-row>

								<b-row class="mb-2" v-if="form.site && no_photo_reporting == 'no'">
									<b-col cols="10" class="mb-2 mt-2"> 
										<b-row>
											<b-col md="3" v-for="(bi,index) in form.images" :key="index">
												<b-media class="mb-2 mt-2" @click="$refs['biInput' +index ][0].click()">
													<template #aside>
														<b-avatar
															:ref="'bI' + index"
															:src="bi.image != null ? bi.image : doc_icon"
															variant="light-warning"
															size="200px"
															rounded
														/>
													</template>
													<div class="d-flex flex-wrap">
														<input :ref="'biInput' + index" type="file" class="d-none" @input="biInput($event,index)">
													</div>
												</b-media>

												<b-button variant="danger" class="mt-0 ml-5 mr-0" @click="removeBI(index)">
													<feather-icon icon="XIcon" />
												</b-button>
											</b-col>
										</b-row>
									</b-col>

									<b-col md="2" >
										<b-button variant="warning" class="mt-2 ml-50 mr-0 float-right" @click="addBI()" v-if="form.images.length < 5">
											<feather-icon icon="PlusCircleIcon" />
											<span class="align-middle ml-50">Add More</span>
										</b-button>
									</b-col>
								</b-row>
								
								<b-row>
									<b-col class="daily_report_sign">
										<b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" type="submit" variant="warning" class="mr-1">
											Submit
										</b-button>

										<b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" variant="outline-secondary" @click="$router.push({ name:'defects' })">
											Cancel
										</b-button>
									</b-col>
								</b-row>

							</b-col>
						</b-row>

					</b-form>
				</b-card>
			</b-col>
		</b-row>

		<b-modal
			id="add-location"
			ref="add-location"
			cancel-variant="outline-secondary"
			ok-variant="outline-warning"
			ok-title="Submit"
			cancel-title="Close"
			centered
			title="Add Location"
			@show="resetModal"
			@hidden="resetModal"
			@ok="addLocation"
		>
			<b-alert v-model="popshowDismissibleAlert" variant="danger" dismissible class="mb-1">
				<div class="alert-body">
					{{error_message}}
				</div>
			</b-alert>

			<b-form>
				<b-form-group>
					<label for="email">Location Name:</label>
					<!-- <b-form-input placeholder="" v-model="location_name" /> -->
					<vue-simple-suggest
						v-model="location_name"
						:list="location_list"
						:filter-by-query="true"
						placeholder="3 to 100 characters"
						:class="$store.state.appConfig.layout.skin == 'dark' ? 'darkSimpleSuggest' : 'lightSimpleSuggest'"
					>
					</vue-simple-suggest>
				</b-form-group>
			</b-form>
		</b-modal>
	
	</div>
</template>

<script>

import {
  	BCard,BRow,BCol,BFormGroup,BFormInput,BFormCheckbox,BForm,BButton,BAlert,BFormSelect,BFormSelectOption,BFormText,BInputGroupPrepend,
	BInputGroup,BMedia,BAvatar,BTable,BModal, BFormRadio,BFormTimepicker,BFormTextarea,BFormDatepicker,BBreadcrumb
} from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';
import { GET_API, POST_API } from "../../../store/actions.type";
import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';
import moment from "moment-timezone";
import vSelect from 'vue-select';
import DateRangePicker from 'vue2-daterange-picker';
//you need to import the CSS manually (in case you want to override it)
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
import Datepicker from 'vuejs-datepicker';
import flatPickr from 'vue-flatpickr-component'
import VueSimpleSuggest from 'vue-simple-suggest'
import 'vue-simple-suggest/dist/styles.css' // Optional CSS

export default {
	components: {
		Datepicker,BAlert,BCard,BRow,BCol,BFormGroup,BFormInput,BFormCheckbox,BForm,BButton,BFormSelect,BFormSelectOption,BFormText,BInputGroupPrepend,BInputGroup,
		BMedia,BAvatar,BTable,BModal,VueCropper,BFormRadio,BFormTimepicker,DateRangePicker,BFormTextarea,vSelect,BFormDatepicker,flatPickr,BBreadcrumb, VueSimpleSuggest
	},

	directives: {
		Ripple,
	},

	data() {
		return {
			clearButton:true,
			error_message:null,
			showDismissibleAlert:false,
			popshowDismissibleAlert:false,
			doc_icon: require('@/assets/images/doc.png'),
			minutes:[],
			form :{
				title:'',
				site:'',
				locations:[],
				supervisor:'',
				shift:'AM',
				description:'',
				dateRange: {
					startDate: new Date(),
					endDate: new Date()
				},
				minutes:'',
				startTime:'00:00',
				endTime:'00:00',
				start_modify:'no',
				end_modify:'no',
				plannedStartTime:'00:00',
				planned_time_modify:'no',
				images:[{
					image:null,
					name:'',
					type:'new',
				}],
				signage:'',
				call_fmcs:'',
				call_fmcs_time:'',
				other:'',
				
			},
			highlighted : {
				dates: [
					new Date()
				]
			},
			showUrgent:'no',
			start_date:'',
			end_date:'',
			sites:[],
			locations:[],
			supervisors:[],
			location_name:'',
			no_photo_reporting : 'no',
			location_list: [],
			show_additional_info : 'no',
		}
	},

	methods : {
		addBI(){
			var bi = {
				image:null,
				name:'',
			}
			this.form.images.push(bi);
		},

		biInput(event,index){
			var input = event.target;
			var files = event.target.files

			if (input.files && input.files[0]) {
				var reader = new FileReader();
				reader.readAsDataURL(input.files[0]);
				var image = input.files[0];

				if (image.type != 'image/png' && image.type != 'image/jpeg' && image.type != 'image/jpg') {					
					Swal.fire({
						position: 'center',
						icon: 'warning',
						title: 'Please upload .png, .jpeg, .jpg image only',
						showConfirmButton: false,
						timer: 1500
						})
					this.$refs['biInput'+index][0].value=null;
				} else if (image.size > 2097152) {
					Swal.fire({
						position: 'center',
						icon: 'warning',
						title: 'Maximum 2 MB files allowed to be upload.',
						showConfirmButton: false,
						timer: 1500
						})					
					this.$refs['biInput'+index][0].value=null;
				} else {
					reader.onload = (e) => {
						this.form.images[index].image = e.target.result;
						this.form.images[index].name = image.name;
						this.form.images[index].type = 'new';
					}
				}
			}
		},

		removeBI(index){
			this.form.images.splice(index,1);
			if (this.form.images.length == 0) {
				this.form.images.push({ image:null,name:''});
			}
		},

		formSubmit(e){
			e.preventDefault();

			return this.$store.dispatch(POST_API, {
				data:{
					items:this.form
				},
				api: '/api/add-defect-request'
			})
			.then(() => {
				if (this.$store.getters.containsErrors) {
					this.error_message = this.$store.getters.getErrors;
					this.showDismissibleAlert = true;
					window.scrollTo(0,0);
				} else {
					this.showDismissibleAlert = false;
					Swal.fire({
						position: 'center',
						icon: 'success',
						title: 'Defect Request created Successfully.',
						showConfirmButton: false,
						timer: 1500
					}).then((result) => {
						this.$router.push({ name:'defects' })
					});
				}
			});
		},

		allSites(){
			return this.$store.dispatch(POST_API, {
				data:{
					role:this.$store.getters.currentUser.role,
					om_sites:this.$store.getters.currentUser.om_sites,
				},
				api: '/api/all-sites'
			})
			.then(() => {
				if (this.$store.getters.containsErrors) {
					this.error_message = this.$store.getters.getErrors;
					this.showDismissibleAlert = true;
					window.scrollTo(0,0);
				} else {
					this.showDismissibleAlert = false;
					var data  = this.$store.getters.getResults.data;
					this.sites = data;
					return this.sites;
				}
			});
		},

		allLocations(){
			return this.$store.dispatch(POST_API, {
				data:{
					site:this.form.site
				},
				api: '/api/all-locations'
			})
			.then(() => {
				if (this.$store.getters.containsErrors) {
					this.error_message = this.$store.getters.getErrors;
					this.showDismissibleAlert = true;
					window.scrollTo(0,0);
				} else {
					this.showDismissibleAlert = false;
					var data  = this.$store.getters.getResults.data;					
					this.locations = data;
					this.location_list = data.map(el => el.name);
					return this.locations;
				}
			});
		},

		allSupervisor(){
			return this.$store.dispatch(POST_API, {
				data:{
					site:this.form.site
				},
				api: '/api/all-supervisors'
			})
			.then(() => {
				if (this.$store.getters.containsErrors) {
					this.error_message = this.$store.getters.getErrors;
					this.showDismissibleAlert = true;
					window.scrollTo(0,0);
				} else {
					this.showDismissibleAlert = false;
					var data  = this.$store.getters.getResults.data;
					var sup = null;
					for (var i = 0; i < data.length; i++) {
						if (data[i].main_supervisor == 'yes') {
							this.form.supervisor = data[i]._id;
						}
					}
					this.form.supervisor = this.form.supervisor ? this.form.supervisor : '';
					this.supervisors = data;
					return this.supervisors;
				}
			});
		},

		resetData(){
			this.no_photo_reporting = 'no';
			this.show_additional_info = 'no';

			this.form.locations = [];
			this.form.supervisor = '';
			
			this.form.signage = '';
			this.form.call_fmcs = '';
			this.form.call_fmcs_time = '';
			this.form.other = '';

			this.allLocations();
			this.allSupervisor();
			if(this.form.site != '') {
				this.siteDetail();
			}else{
				this.showUrgent = 'no';
			}
		},

		getNoPhotoReporting(){
			this.sites.forEach((el) => {
				if(el._id == this.form.site){
					this.no_photo_reporting = el.no_photo_reporting;
				}
			})
		},

		resetModal(){
			// this.form.locations = [];
			this.location_name = '';
			this.popshowDismissibleAlert = false;
		},

		openModel(){
			// this.form.locations = [];
			this.$refs['add-location'].show();
		},

		addLocation(bvModalEvt){
			bvModalEvt.preventDefault()
		
			return this.$store.dispatch(POST_API, {
				data:{
					location_name:this.location_name,
					site:this.form.site
				},
				api: '/api/add-other-location'
			})
			.then(() => {
				if (this.$store.getters.containsErrors) {
					this.error_message = this.$store.getters.getErrors;
					this.popshowDismissibleAlert = true;
				} else {
					this.popshowDismissibleAlert = false;
					this.allLocations();
					this.$refs['add-location'].hide();
				}
			});
		},

		customFormatter(date) {
			return moment(date).format('DD MMM YYYY');
		},

		siteDetail(){
			return this.$store.dispatch(POST_API, {
				data:{
					id:this.form.site
				},
				api: '/api/site-detail'
			})
			.then(() => {
				if (this.$store.getters.containsErrors) {
					this.error_message = this.$store.getters.getErrors;
					this.showDismissibleAlert = true;
					window.scrollTo(0,0);
				} else {
					this.showDismissibleAlert = false;
					var data  = this.$store.getters.getResults.data;
					if (data.kpi_contract == 'yes') {
						this.showUrgent = 'yes';
						this.minutes = [];
						var n = [];
						data.minutes.forEach(item => {
							n.push(item.value);
						})
						this.minutes = n;					
					} else {
						this.showUrgent = 'no';
						this.minutes = [];
					}

					this.show_additional_info = data.additional_info_defect;					
				}
			});
		},

		breadCrumb(){
			var item = [{
				to:{name:'client-dashboard'},
				text: 'Dashboard',
			},{
				to:null,
				text: 'Service Request'
			},{
				to:{name:'defects'},
				text: 'Defects'
			},{
				to:null,
				text: 'Add Defect',
				active:true
			}];
			return item;
		}
	},

	mounted(){
		this.allSites();
	}
}
</script>

<style lang="scss">
	@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>

